.form-component::-webkit-scrollbar, 
.quiz-component div:first-of-type::-webkit-scrollbar {
  width: 12.8px;
  height: 18px;
}

.form-component::-webkit-scrollbar-track, 
.quiz-component div:first-of-type::-webkit-scrollbar-track  {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}

.form-component::-webkit-scrollbar-thumb,
.quiz-component div:first-of-type::-webkit-scrollbar-thumb  {
  height: 6px;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  background-color: rgba(0, 0, 0, 0.15);
  box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0),
              inset 1px 1px 0px rgba(0, 0, 0, 0);
  -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0),
                      inset 1px 1px 0px rgba(0, 0, 0, 0);
}
