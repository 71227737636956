@import url('https://fonts.googleapis.com/css?family=Mulish:400,600,800&display=swap');
@import url('https://fonts.googleapis.com/css?family=Raleway:400,500,600&display=swap');

#serverless-landing-from {
  @import '~bootstrap/scss/bootstrap';
  @import 'dayPicker';
  @import 'scrollBar';
  @import 'normalize';
  font-family: 'Raleway', sans-serif;
  color: #414141;
  overflow-x: hidden;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    display: inline-block;
    padding: 0;
    margin: 0;
  }

  strong,
  b {
    font-weight: 500;
  }

  .container {
    padding-right: 0;
    padding-left: 0;
  }

  div:empty:not(.DayPicker-Day--outside) {
    display: none;
  }
}

.es-es,
.it-it,
.pt-pt,
.de-de,
.en-us {
  width: 100%;
}

div#serverless-landing-container.row {
  margin-top: 0 !important;
}

#serverless-landing-from p {
  margin-bottom: 0;
}

#serverless-landing-from .ganas-sol-title {
  max-width: 680px;
  margin-left: auto;
  margin-right: auto;
  p {
    font-size: 30px;
    line-height: 38px;
    strong {
      font-size: 40px;
      line-height: 49px;
      margin-bottom: 10px;
    }
    @media (max-width: 768px) {
      strong {
        width: 80%;
        font-size: 27px;
        line-height: 31px;
        margin-bottom: 10px;
      }
      font-size: 20px;
      line-height: 27px;
    }
  }
}

@media only screen and (max-width: 1023px) {
  #serverless-landing-from {
    br {
      display: block !important;
    }
  }
}

// disable popup usa
// body {margin: 0}
// html, body, body.no-scroll {
//   overflow-y: inherit !important;
//   overflow-X: hidden !important;
// }
// .awesome-dialog.newsletter-modal-popup.visible {
//   display: none;
// }

// ._m-modal._m-modal--shown {
//   display: none;
// }

/* DayPicker styles */

.DayPicker {
  display: inline-block;
  font-size: 16px;
}

.DayPicker-wrapper {
  position: relative;

  flex-direction: row;
  padding-bottom: 16px;

  -webkit-user-select: none;

  -moz-user-select: none;

  -ms-user-select: none;

  user-select: none;
}

.DayPicker-Months {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.DayPicker-Month {
  display: table;
  margin: 0 16px;
  margin-top: 16px;
  border-spacing: 0;
  border-collapse: collapse;

  -webkit-user-select: none;

  -moz-user-select: none;

  -ms-user-select: none;

  user-select: none;
}

.DayPicker-NavBar {
}

.DayPicker-NavButton {
  position: absolute;
  top: 16px;
  right: 16px;
  left: auto;

  display: inline-block;
  margin-top: 2px;
  width: 20px;
  height: 20px;
  background-position: center;
  background-size: 50%;
  background-repeat: no-repeat;
  color: #8b9898;
  cursor: pointer;
}

.DayPicker-NavButton:hover {
  opacity: 0.8;
}

.DayPicker-NavButton--prev {
  margin-right: 24px;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAVVJREFUWAnN2G0KgjAYwPHpGfRkaZeqvgQaK+hY3SUHrk1YzNLay/OiEFp92I+/Mp2F2Mh2lLISWnflFjzH263RQjzMZ19wgs73ez0o1WmtW+dgA01VxrE3p6l2GLsnBy1VYQOtVSEH/atCCgqpQgKKqYIOiq2CBkqtggLKqQIKgqgCBjpJ2Y5CdJ+zrT9A7HHSTA1dxUdHgzCqJIEwq0SDsKsEg6iqBIEoq/wEcVRZBXFV+QJxV5mBtlDFB5VjYTaGZ2sf4R9PM7U9ZU+lLuaetPP/5Die3ToO1+u+MKtHs06qODB2zBnI/jBd4MPQm1VkY79Tb18gB+C62FdBFsZR6yeIo1YQiLJWMIiqVjQIu1YSCLNWFgijVjYIuhYYCKoWKAiiFgoopxYaKLUWOii2FgkophYp6F3r42W5A9s9OcgNvva8xQaysKXlFytoqdYmQH6tF3toSUo0INq9AAAAAElFTkSuQmCC');
}

.DayPicker-NavButton--next {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAXRJREFUWAnN119ugjAcwPHWzJ1gnmxzB/BBE0n24m4xfNkTaOL7wOtsl3AXMMb+Vjaa1BG00N8fSEibPpAP3xAKKs2yjzTPH9RAjhEo9WzPr/Vm8zgE0+gXATAxxuxtqeJ9t5tIwv5AtQAApsfT6TPdbp+kUBcgVwvO51KqVhMkXKsVJFXrOkigVhCIs1Y4iKlWZxB1rX4gwlpRIIpa8SDkWmggrFq4IIRaJKCYWnSgnrXIQV1r8YD+1Vrn+bReagysIFfLABRt31v8oBu1xEBttfRbltmfjgEcWh9snUS2kNdBK6WN1vrOWxObWsz+fjxevsxmB1GQDfINWiev83nhaoiB/CoOU438oPrhXS0WpQ9xc1ZQWxWHqUYe0I0qrKCQKjygDlXIQV2r0IF6ViEBxVTBBSFUQQNhVYkHIVeJAtkNsbQ7c1LtzP6FsObhb2rCKv7NBIGoq4SDmKoEgTirXAcJVGkFSVVpgoSrXICGUMUH/QBZNSUy5XWUhwAAAABJRU5ErkJggg==');
}

.DayPicker-NavButton--interactionDisabled {
  display: none;
}

.DayPicker-Caption {
  display: table-caption;
  margin-bottom: 8px;
  padding: 0 8px;
  text-align: left;
}

.DayPicker-Caption > div {
  font-weight: 500;
  font-size: 18.4px;
}

.DayPicker-Weekdays {
  display: table-header-group;
  margin-top: 16px;
}

.DayPicker-WeekdaysRow {
  display: table-row;
}

.DayPicker-Weekday {
  display: table-cell;
  padding: 8px;
  color: #8b9898;
  text-align: center;
  font-size: 14px;
}

.DayPicker-Weekday abbr[title] {
  border-bottom: none;
  text-decoration: none;
}

.DayPicker-Body {
  display: table-row-group;
}

.DayPicker-Week {
  display: table-row;
}

.DayPicker-Day {
  display: table-cell;
  padding: 8px;
  border-radius: 50%;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
}

.DayPicker-WeekNumber {
  display: table-cell;
  padding: 8px;
  min-width: 16px;
  border-right: 1px solid #eaecec;
  color: #8b9898;
  vertical-align: middle;
  text-align: right;
  font-size: 12px;
  cursor: pointer;
}

.DayPicker--interactionDisabled .DayPicker-Day {
  cursor: default;
}

.DayPicker-Footer {
  padding-top: 8px;
}

.DayPicker-TodayButton {
  border: none;
  background-color: transparent;
  background-image: none;
  box-shadow: none;
  color: #4a90e2;
  font-size: 14px;
  cursor: pointer;
}

/* Default modifiers */

.DayPicker-Day--today {
  color: #414141;
  font-weight: 700;
}

.DayPicker-Day--outside {
  color: #8b9898;
  cursor: default;
}

.DayPicker-Day--disabled {
  color: #dce0e0;
  cursor: default;
  /* background-color: #eff1f1; */
}

/* Example modifiers */

.DayPicker-Day--sunday {
  background-color: #f7f8f8;
}

.DayPicker-Day--sunday:not(.DayPicker-Day--today) {
  color: #dce0e0;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  position: relative;

  background-color: #414141;
  color: #f0f8ff;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: #414141;
}

.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: #cecfcf;
}

/* DayPickerInput */

.DayPickerInput {
  display: inline-block;
}

.DayPickerInput-OverlayWrapper {
  position: relative;
}

.DayPickerInput-Overlay {
  position: absolute;
  left: 0;
  z-index: 1;

  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

form input {
  background-color: #f5f5f5;
}
